import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import GitHubIcon from '@mui/icons-material/GitHub'
import DescriptionIcon from '@mui/icons-material/Description'
import { Grid } from '@mui/material'
import Link from '@mui/material/Link'

import Card from './Card'

import { BLACK, FONT_PRIMARY, PURPLE, WHITE } from './styles'
import { Title, Subtitle } from './styled-components'

const IconWrapper = styled.div({
  borderRadius: '50%',
  backgroundColor: WHITE,
  padding: 4,
  width: 'fit-content',
  display: 'flex',
  margin: 6,
  cursor: 'pointer',
})

const CONTACT_ME = 'Contact Me'
const RESUME_URL =
  'https://docs.google.com/document/d/1ehFhWX4hAJsFqbiHnMg86UkZnTFRDOTOi-B5YzbdZ-s/edit?usp=sharing'

const IntroCard = ({
  title,
  subtitle,
  width,
  linkedin,
  github,
  instagram,
  email,
  containerStyles,
}) => {
  const socialMediaIconsConfig = [
    { IconComponent: DescriptionIcon, url: RESUME_URL },
    { IconComponent: LinkedInIcon, url: linkedin },
    { IconComponent: GitHubIcon, url: github },
  ]
  return (
    <Card
      width={width}
      innerStyles={{
        padding: 6,
        background:
          'radial-gradient(circle, rgba(11,217,4,0.1) 0%,  rgba(124,5,242,0.1) 100%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
      }}
      containerStyles={containerStyles}
    >
      <div style={{ margin: '12px 24px' }}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </div>

      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-evenly',
          alignItems: { xs: 'stretch', md: 'center' },
          padding: '12px',
        }}
      >
        <Grid item sx={{ padding: '12px 24px 0px', textAlign: 'center' }}>
          <Button
            variant="contained"
            sx={{
              width: { xs: '80%', md: '200px' },
              textAlign: { xs: 'center' },
              borderRadius: 24,
              fontFamily: FONT_PRIMARY,
              textTransform: 'none',
              backgroundColor: PURPLE,
              opacity: 1,
              '&:hover': {
                backgroundColor: PURPLE,
                opacity: 0.5,
              },
            }}
          >
            <Link
              href={`mailto:${email}`}
              underline="none"
              target="_blank"
              noreferrer="true"
              noopener="true"
              sx={{ textDecoration: 'none', color: 'inherit' }}
            >
              {CONTACT_ME}
            </Link>
          </Button>
        </Grid>

        <Grid item>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: { xs: 'space-evenly', md: 'flex-start' },
            }}
          >
            {socialMediaIconsConfig.map(({ IconComponent, url }, idx) => (
              <Link
                key={idx}
                href={url}
                underline="none"
                target="_blank"
                noreferrer="true"
                noopener="true"
              >
                <IconWrapper>
                  <IconComponent
                    sx={{
                      color: BLACK,
                      opacity: 0.5,
                      margin: '6px',
                      '&:hover': {
                        color: PURPLE,
                        cursor: 'pointer',
                      },
                    }}
                    fontSize="medium"
                  />
                </IconWrapper>
              </Link>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

IntroCard.propTypes = {
  email: PropTypes.string.isRequired,
  github: PropTypes.string.isRequired,
  instagram: PropTypes.string.isRequired,
  linkedin: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.number.isRequired,
  containerStyles: PropTypes.object,
}

IntroCard.defaultProps = {
  title: null,
  containerStyles: {},
}

export default IntroCard
