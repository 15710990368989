import { createClient } from 'contentful'

const useContentful = () => {
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
    host: process.env.REACT_APP_CONTENTFUL_HOST,
  })

  const fetchContentfulData = async contentType => {
    try {
      const entries = await client.getEntries({
        content_type: contentType,
        select: 'fields',
      })
      return entries
    } catch (error) {
      console.log(error)
    }
  }

  return fetchContentfulData
}

export default useContentful
