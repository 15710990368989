export const DEFAULT_INFO = {
  name: 'Anh',
  portrait: {
    metadata: {
      tags: [],
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: 'ob0dzye56ni7',
        },
      },
      id: '68YNDARzZ4JQbmTkxCkQxC',
      type: 'Asset',
      createdAt: '2023-01-12T03:08:49.992Z',
      updatedAt: '2023-01-12T13:22:06.040Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment',
        },
      },
      revision: 2,
      locale: 'en-US',
    },
    fields: {
      title: 'sriracha-anh',
      description: '',
      file: {
        url: '//images.ctfassets.net/ob0dzye56ni7/68YNDARzZ4JQbmTkxCkQxC/36287371033f792cf06bfe7c129aaed2/324752416_1307153136516988_7176390506558178751_n.jpg',
        details: {
          size: 370016,
          image: {
            width: 2016,
            height: 1512,
          },
        },
        fileName: '324752416_1307153136516988_7176390506558178751_n.jpg',
        contentType: 'image/jpeg',
      },
    },
  },
  introTitle: "👋 Hello, I'm Anh, a frontend software engineer.",
  introSubtitle:
    'I believe that code cleanliness is next to godliness, that there is power in simplicity, and that happiness comes not from money, but from cats. 🐱',
  email: 'vuonga1103@yahoo.com',
  linkedin: 'https://www.linkedin.com/in/vuonganht/',
  github: 'https://github.com/vuonga1103',
  instagram: 'https://www.instagram.com/hello_world_its_me_anh/',
  contactTitle: "☎️ Let's chat!",
  contactSubtitle:
    'If you would like to get in touch, please feel free to reach out to me via LinkedIn or email at vuonga1103@yahoo.com. I am always happy to connect with others in the industry and explore potential opportunities to collaborate.',
  avatar: {
    metadata: {
      tags: [],
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: 'ob0dzye56ni7',
        },
      },
      id: '1Y0Lk4FFkeqGsGIZlpoSao',
      type: 'Asset',
      createdAt: '2023-01-12T04:22:21.563Z',
      updatedAt: '2023-01-12T04:22:21.563Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment',
        },
      },
      revision: 1,
      locale: 'en-US',
    },
    fields: {
      title: 'avatar',
      description: '',
      file: {
        url: '//images.ctfassets.net/ob0dzye56ni7/1Y0Lk4FFkeqGsGIZlpoSao/0c95138687e8d0eee4eaf90d160eb45f/58869812.png',
        details: {
          size: 214445,
          image: {
            width: 460,
            height: 460,
          },
        },
        fileName: '58869812.png',
        contentType: 'image/png',
      },
    },
  },
}

export const DEFAULT_NOW = [
  {
    category: 'Misc',
    item: 'Currently prepping for NYCRUNS Central Park Half Marathon with Pulse Running Club',
    date: '2023-01-01T00:00-05:00',
  },
  {
    category: 'Read',
    item: 'Never Split the Difference by Christopher Voss and Tahl Raz',
    date: '2023-01-01T00:00-05:00',
  },
  {
    category: 'Learn',
    item: 'Learning Typescript by Josh Goldberg',
    date: '2023-01-01T00:00-05:00',
  },
  {
    category: 'Learn',
    item: "Frontend Masters' React and TypeScript by Steve Kinney",
    date: '2023-01-01T00:00-05:00',
  },
  {
    category: 'Build',
    item: 'Reviving my portfolio and student projects whilst experimenting with headless CMS’s like Contentful and Strapi. ',
    date: '2023-01-01T00:00-05:00',
  },
  {
    category: 'Build',
    item: 'Built a proof of concept for state storage of a multi-user concurrently editable data table, utilizing Redis and socket.io.',
    date: '2023-01-01T00:00-05:00',
  },
]

export const DEFAULT_PROJECTS = [
  {
    title: 'Who is Justice Beaver?',
    subtitle: 'An Office quiz game',
    link: 'https://who-is-justice-beaver.netlify.app/',
    image: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'ob0dzye56ni7',
          },
        },
        id: '67ql7EtqqRScqQFSpoGZ1Z',
        type: 'Asset',
        createdAt: '2023-01-12T03:12:55.931Z',
        updatedAt: '2023-01-12T04:52:05.869Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 2,
        locale: 'en-US',
      },
      fields: {
        title: 'who is justice beaver',
        description: '',
        file: {
          url: '//images.ctfassets.net/ob0dzye56ni7/67ql7EtqqRScqQFSpoGZ1Z/2745e1eeca1d90d0500f1baccb5a9a52/Screenshot_2023-01-11_at_11.51.24_PM.png',
          details: {
            size: 971193,
            image: {
              width: 1588,
              height: 1114,
            },
          },
          fileName: 'Screenshot 2023-01-11 at 11.51.24 PM.png',
          contentType: 'image/png',
        },
      },
    },
    width: 6,
    order: 1,
    tools: ['React', 'Typescript', 'React Testing Library'],
  },
  {
    title: 'Access-A-Place',
    subtitle:
      'Crowdsourced reviews of establishment accessibility for people with disabilities',
    image: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'ob0dzye56ni7',
          },
        },
        id: '4Cf3yd1TOMQy8vqfuWHn50',
        type: 'Asset',
        createdAt: '2023-01-12T03:17:11.086Z',
        updatedAt: '2023-01-12T04:53:45.922Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 2,
        locale: 'en-US',
      },
      fields: {
        title: 'access-a-place',
        description: '',
        file: {
          url: '//images.ctfassets.net/ob0dzye56ni7/4Cf3yd1TOMQy8vqfuWHn50/6bb7f4add183d7f5125a2e686bb4a9ba/Screenshot_2023-01-11_at_11.53.25_PM.png',
          details: {
            size: 1048418,
            image: {
              width: 1990,
              height: 1222,
            },
          },
          fileName: 'Screenshot 2023-01-11 at 11.53.25 PM.png',
          contentType: 'image/png',
        },
      },
    },
    width: 6,
    order: 2,
    youtubeUrl: 'https://www.youtube.com/embed/tyQ_Nnp3sus',
    tools: [
      'Ruby on Rails',
      'React',
      'Redux',
      'PostgreSQL',
      'Bulma',
      'Yelp Fusion',
      'OmniAuth Google OAuth2',
      'Mapbox',
    ],
  },
  {
    title: 'Equip',
    subtitle: 'An online market place for medical equipment',
    image: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'ob0dzye56ni7',
          },
        },
        id: '4YvFs8CmyLgxL9MkG9KWoz',
        type: 'Asset',
        createdAt: '2023-01-12T03:18:54.371Z',
        updatedAt: '2023-01-12T05:03:19.769Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 2,
        locale: 'en-US',
      },
      fields: {
        title: 'equip',
        description: '',
        file: {
          url: '//images.ctfassets.net/ob0dzye56ni7/4YvFs8CmyLgxL9MkG9KWoz/f3133f20556beaf008052067b7d68435/Screenshot_2023-01-12_at_12.02.59_AM.png',
          details: {
            size: 594867,
            image: {
              width: 1904,
              height: 1118,
            },
          },
          fileName: 'Screenshot 2023-01-12 at 12.02.59 AM.png',
          contentType: 'image/png',
        },
      },
    },
    width: 6,
    order: 3,
    youtubeUrl: 'https://www.youtube.com/embed/82_NDlwGPWs',
    tools: [
      'Ruby on Rails',
      'React',
      'PostgreSQL',
      'Semantic UI',
      'Cloudinary',
      'Google Geocoding',
      'Google Maps',
    ],
  },
  {
    title: 'Afternoon Roast',
    subtitle: 'A Morning Brew-inspired simple content management system',
    image: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'ob0dzye56ni7',
          },
        },
        id: '2TUwCrSvROl0e6wihIMQmg',
        type: 'Asset',
        createdAt: '2023-01-12T03:20:38.324Z',
        updatedAt: '2023-01-12T05:01:07.145Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 2,
        locale: 'en-US',
      },
      fields: {
        title: 'afternoon roast',
        description: '',
        file: {
          url: '//images.ctfassets.net/ob0dzye56ni7/2TUwCrSvROl0e6wihIMQmg/444ba31e0ff55705854a5d92f47f2e28/Screenshot_2023-01-12_at_12.00.41_AM.png',
          details: {
            size: 232701,
            image: {
              width: 1510,
              height: 840,
            },
          },
          fileName: 'Screenshot 2023-01-12 at 12.00.41 AM.png',
          contentType: 'image/png',
        },
      },
    },
    width: 6,
    order: 4,
    youtubeUrl: 'https://www.youtube.com/embed/nqb_-oCB6AM',
    tools: ['Ruby on Rails', 'React', 'PostgreSQL', 'Material UI', 'TinyMCE'],
  },
  {
    title: 'MovieFind',
    subtitle:
      'A simple movie app that allows user to search, view, and like/dislike movies.',
    image: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'ob0dzye56ni7',
          },
        },
        id: '5qWPYh9dssW2VM9sBPQAJW',
        type: 'Asset',
        createdAt: '2023-02-04T04:57:17.754Z',
        updatedAt: '2023-02-04T04:57:50.484Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 2,
        locale: 'en-US',
      },
      fields: {
        title: 'moviefind',
        description: '',
        file: {
          url: '//images.ctfassets.net/ob0dzye56ni7/5qWPYh9dssW2VM9sBPQAJW/72af9f141d09d888f003a6cab78d8c47/Screenshot_2023-02-03_at_11.57.32_PM.png',
          details: {
            size: 474290,
            image: {
              width: 823,
              height: 450,
            },
          },
          fileName: 'Screenshot 2023-02-03 at 11.57.32 PM.png',
          contentType: 'image/png',
        },
      },
    },
    width: 5,
    youtubeUrl: 'https://www.youtube.com/embed/6yIvV6Rtz44',
    tools: ['Ruby on Rails', 'React', 'PostgreSQL'],
  },
]

export const DEFAULT_TIMELINE = [
  {
    event: 'Software Engineer II',
    description: 'Pulse Analytics',
    timespan: '2021 - Present',
    icon: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'ob0dzye56ni7',
          },
        },
        id: '2k06QPOZKstEaC1tsDQfnY',
        type: 'Asset',
        createdAt: '2023-01-21T20:35:35.247Z',
        updatedAt: '2023-01-21T20:35:35.247Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 1,
        locale: 'en-US',
      },
      fields: {
        title: 'pulse',
        description: '',
        file: {
          url: '//images.ctfassets.net/ob0dzye56ni7/2k06QPOZKstEaC1tsDQfnY/0f3e998b79cf4eee18e611c481bf3797/1607355528926.jpeg',
          details: {
            size: 2055,
            image: {
              width: 100,
              height: 100,
            },
          },
          fileName: '1607355528926.jpeg',
          contentType: 'image/jpeg',
        },
      },
    },
    tooltipText:
      "Currently building performant & engaging data visualization dashboards that are instrumental in aiding our healthcare clients' decision-making process, by leveraging modern techs such as React, Redux, and GraphQL.",
  },
  {
    event: 'Flatiron School',
    description: 'Software Engineering Certificate',
    timespan: '2020',
    icon: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'ob0dzye56ni7',
          },
        },
        id: '2TY9Tqx1V7dzoYi57jJBea',
        type: 'Asset',
        createdAt: '2023-01-21T20:31:43.616Z',
        updatedAt: '2023-01-21T20:31:43.616Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 1,
        locale: 'en-US',
      },
      fields: {
        title: 'flatiron',
        description: '',
        file: {
          url: '//images.ctfassets.net/ob0dzye56ni7/2TY9Tqx1V7dzoYi57jJBea/25d2bc3a73092b1a121a85e941e0ec35/1673023108480.jpeg',
          details: {
            size: 1854,
            image: {
              width: 100,
              height: 100,
            },
          },
          fileName: '1673023108480.jpeg',
          contentType: 'image/jpeg',
        },
      },
    },
    tooltipText: 'Recipient of NYC Tech Talent Pipeline scholarship',
  },
  {
    event: 'Software Engineer',
    description: 'Femmecubator',
    timespan: '2020 - 2021',
    icon: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'ob0dzye56ni7',
          },
        },
        id: '28oSIJrZsN09EhLEamMKdr',
        type: 'Asset',
        createdAt: '2023-01-21T20:32:45.102Z',
        updatedAt: '2023-01-21T20:32:45.102Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 1,
        locale: 'en-US',
      },
      fields: {
        title: 'femmecubator',
        description: '',
        file: {
          url: '//images.ctfassets.net/ob0dzye56ni7/28oSIJrZsN09EhLEamMKdr/1c9da160a4ef54fc89cc0d2db28b3d25/1605363300032.jpeg',
          details: {
            size: 2510,
            image: {
              width: 100,
              height: 100,
            },
          },
          fileName: '1605363300032.jpeg',
          contentType: 'image/jpeg',
        },
      },
    },
    tooltipText:
      'Contributed to the fullstack development of a community-driven platform that supports women who are navigating a career in tech, utilizing the MERN stack.',
  },
  {
    event: 'Full Stack Developer',
    description: 'DGA Security',
    timespan: '2021',
    icon: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'ob0dzye56ni7',
          },
        },
        id: '77vYPH4wN4LfBlAiqnhB4t',
        type: 'Asset',
        createdAt: '2023-01-21T20:34:37.822Z',
        updatedAt: '2023-01-21T20:34:37.822Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 1,
        locale: 'en-US',
      },
      fields: {
        title: 'dga',
        description: '',
        file: {
          url: '//images.ctfassets.net/ob0dzye56ni7/77vYPH4wN4LfBlAiqnhB4t/8cb11fd816c125dadd1b2403d292e90e/1554479593690.jpeg',
          details: {
            size: 3942,
            image: {
              width: 100,
              height: 100,
            },
          },
          fileName: '1554479593690.jpeg',
          contentType: 'image/jpeg',
        },
      },
    },
    tooltipText:
      'Contributed to the continuous improvement of user portal web application via debugging various display and backend issues, utilizing tools such as MASterMind, CodeIgniter, jQuery, Ajax, Bootstrap, MySQL, Postman, and Jenkins.',
  },
  {
    event: 'SUNY Downstate',
    description: 'MS in Occupational Therapy, with honors',
    timespan: '2018',
    icon: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'ob0dzye56ni7',
          },
        },
        id: '6JaOaVAJ1dstX1DuEFICiC',
        type: 'Asset',
        createdAt: '2023-01-21T20:29:27.578Z',
        updatedAt: '2023-01-21T20:29:27.578Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 1,
        locale: 'en-US',
      },
      fields: {
        title: 'suny downstate',
        description: '',
        file: {
          url: '//images.ctfassets.net/ob0dzye56ni7/6JaOaVAJ1dstX1DuEFICiC/67da6608df9a6879246644bc14dea2fa/1639518853198.jpeg',
          details: {
            size: 3391,
            image: {
              width: 100,
              height: 100,
            },
          },
          fileName: '1639518853198.jpeg',
          contentType: 'image/jpeg',
        },
      },
    },
  },
  {
    event: 'Stony Brook University',
    description: 'BS in Disability Studies, cum laude',
    timespan: '2014',
    icon: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'ob0dzye56ni7',
          },
        },
        id: '4seKNHLbeWfF0IpKdFGEaW',
        type: 'Asset',
        createdAt: '2023-01-21T20:28:56.803Z',
        updatedAt: '2023-01-21T20:28:56.803Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 1,
        locale: 'en-US',
      },
      fields: {
        title: 'stony brook icon',
        description: '',
        file: {
          url: '//images.ctfassets.net/ob0dzye56ni7/4seKNHLbeWfF0IpKdFGEaW/5e079dda75ac789599d80bafa19311ce/1619093642837.jpeg',
          details: {
            size: 4626,
            image: {
              width: 100,
              height: 100,
            },
          },
          fileName: '1619093642837.jpeg',
          contentType: 'image/jpeg',
        },
      },
    },
  },
]
