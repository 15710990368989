import PropTypes from 'prop-types'
import React from 'react'

import ProjectCard from './ProjectCard'

import { COLORS } from './styles'

const PROJECT_CARDS_COLORS = Object.values(COLORS)

const ProjectCards = ({ data }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: 24,
        overflow: 'scroll',
        margin: 12,
        padding: 12,
      }}
    >
      {data.map((project, idx) => {
        const { title, subtitle, image, link, width, youtubeUrl, tools } =
          project
        const {
          fields: {
            file: { url: imgUrl },
          },
        } = image

        return (
          <React.Fragment key={title}>
            <ProjectCard
              color={PROJECT_CARDS_COLORS[idx]}
              title={title}
              subtitle={subtitle}
              imgSrc={`http:${imgUrl}`}
              width={width}
              link={link}
              youtubeUrl={youtubeUrl}
              tools={tools}
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}

ProjectCards.propTypes = {
  data: PropTypes.array.isRequired,
}

export default ProjectCards
