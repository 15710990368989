import React from 'react'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { FONT_SECONDARY } from '../styles'

const Dropdown = ({ setSelectedTab, selectedTab, tabs, contentRef }) => {
  const handleChange = event => {
    setSelectedTab(event.target.value)
    contentRef.current.scrollTop = 0
  }

  return (
    <FormControl
      fullWidth
      sx={{
        margin: '24px 12px 6px 24px',
        fontFamily: FONT_SECONDARY,
      }}
    >
      <InputLabel
        id="blog-post-simple-select-label"
        sx={{ fontFamily: 'inherit' }}
      >
        Blog Posts
      </InputLabel>
      <Select
        labelId="blog-post-simple-select-label"
        id="blog-post-simple-select"
        value={selectedTab}
        label="Blog Posts"
        onChange={handleChange}
        sx={{ fontFamily: 'inherit' }}
      >
        {tabs.map(tab => (
          <MenuItem key={tab} value={tab} sx={{ fontFamily: FONT_SECONDARY }}>
            {tab}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default Dropdown
