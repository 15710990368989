import PropTypes from 'prop-types'
import React from 'react'

import Card from './Card'
import { CenteredPhoto } from './styled-components'

const SelfPortraitCard = ({ portraitUrl }) => {
  return (
    <Card
      width={5}
      innerStyles={{
        minHeight: 250,
        position: 'relative',
        overflow: 'hidden',
      }}
      containerStyles={{ order: { xs: '-1', md: 0 } }}
    >
      <CenteredPhoto
        src={portraitUrl}
        alt="Self portrait of author"
        style={{ height: '100%' }}
      />
    </Card>
  )
}

SelfPortraitCard.propTypes = {
  portraitUrl: PropTypes.string.isRequired,
}

export default SelfPortraitCard
