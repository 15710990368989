import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { mix } from 'polished'

import { FONT_SECONDARY, PURPLE, WHITE } from '../styles'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  fontFamily: FONT_SECONDARY,
  marginBottom: 12,
  fontSize: '1rem',
  fontWeight: 800,
  letterSpacing: '-0.03em',
  ':hover': {
    backgroundColor: mix(0.05, PURPLE, WHITE),
  },
})

const Image = styled.img({
  height: 64,
  width: 64,
  objectFit: 'cover',
  borderRadius: 4,
})

const Tab = ({ onClick, text, imageUrl, isSelected }) => {
  const selectedStyles = isSelected
    ? {
        textDecoration: 'underline',
        textDecorationColor: PURPLE,
      }
    : null

  return (
    <Wrapper role="button" onClick={onClick} value={text}>
      <div style={{ marginRight: 12, ...selectedStyles }}>{text}</div>
      {imageUrl && (
        <div>
          <Image src={imageUrl} alt={text} />
        </div>
      )}
    </Wrapper>
  )
}

Tab.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
}

Tab.defaultProps = {
  onClick: () => {},
}

export default Tab
