import PropTypes from 'prop-types'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'

import Modal from './Modal'
import { Title, Subtitle } from './styled-components'
import { PURPLE } from './styles'
import Tools from './Tools'

const ProjectModal = ({
  isModalOpen,
  handleModalClose,
  title,
  subtitle,
  youtubeUrl,
  link,
  tools,
}) => {
  return (
    <Modal open={isModalOpen} handleClose={handleModalClose}>
      <div
        style={{
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            marginBottom: 0,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Title style={{ color: PURPLE }}>{title}</Title>
            <Subtitle style={{ marginTop: -12 }}>{subtitle}</Subtitle>
          </div>
          <CloseIcon
            sx={{ cursor: 'pointer', '&:hover': { color: PURPLE } }}
            onClick={handleModalClose}
          />
        </div>
        <Tools data={tools} />

        {youtubeUrl && (
          <iframe
            width="100%"
            height="100%"
            src={`${youtubeUrl}?&autoplay=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        )}

        {!youtubeUrl && link && (
          <iframe
            src={link}
            height="100%"
            width="100%"
            title="Iframe Example"
          ></iframe>
        )}
      </div>
    </Modal>
  )
}

ProjectModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  link: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  youtubeUrl: PropTypes.string,
  tools: PropTypes.arrayOf(PropTypes.string).isRequired,
}

ProjectModal.defaultProps = {
  link: '',
  youtubeUrl: '',
}

export default ProjectModal
