import PropTypes from 'prop-types'
import React from 'react'
import MuiModal from '@mui/material/Modal'
import Box from '@mui/material/Box'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '70%',
  bgcolor: 'background.paper',
  borderRadius: '18px',
  boxShadow: 24,
  p: 4,
}

const Modal = ({ open, handleClose, children }) => {
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </MuiModal>
  )
}

Modal.propTypes = {
  children: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

Modal.defaultProps = {
  children: null,
}

export default Modal
