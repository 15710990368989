import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'

import { BLACK } from '../styles'

const SectionBtns = ({ sections, handleCloseNavMenu }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: 'none', md: 'flex' },
        justifyContent: 'flex-end',
      }}
    >
      {sections.map(({ label, id }) => (
        <Button
          key={label}
          onClick={handleCloseNavMenu}
          sx={{
            my: 2,
            color: BLACK,
            display: 'block',
            fontFamily: 'inherit',
            textTransform: 'none',
          }}
        >
          <Link href={`#${id}`} underline="none" sx={{ color: 'inherit' }}>
            {label}
          </Link>
        </Button>
      ))}
    </Box>
  )
}

export default SectionBtns
