// testing git notes
import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import sortBy from 'lodash.sortby'
import isEmpty from 'lodash.isempty'

import AppBar from './AppBar'
import IntroCard from './IntroCard'
import SelfPortraitCard from './SelfPortraitCard'
import useContentful from './useContentful'
import TimelineCard from './TimelineCard'

import { SectionTitle } from './styled-components'
import Blog from './Blog'
import ProjectCards from './ProjectCards'
import {
  DEFAULT_INFO,
  DEFAULT_NOW,
  DEFAULT_PROJECTS,
  DEFAULT_TIMELINE,
} from './defaults'

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000
const PROJECTS = 'projects'
const INFO = 'info'
const NOW = 'now'
const TIMELINE = 'timeline'

const isExpired = key => {
  const infoTimestamp = localStorage.getItem(`${key}Timestamp`)
  if (!infoTimestamp) {
    return true
  }
  const timestamp = new Date(infoTimestamp).getTime()
  const currentTime = new Date().getTime()
  return currentTime - timestamp > ONE_DAY_IN_MS
}

const useLocalStorageContent = ({ contentType, setter }) => {
  try {
    const content = JSON.parse(localStorage.getItem(contentType))
    setter(content)
  } catch (error) {
    console.error(error)
  }
}

const useFetchedContent = ({ contentType, setter, fetchContentfulData }) => {
  fetchContentfulData(contentType).then(response => {
    let data
    if (contentType === INFO) {
      data = response?.items[0]?.fields
    } else {
      data = response?.items?.map(({ fields }) => fields)
    }

    setter(data)
    localStorage.setItem(contentType, JSON.stringify(data))
    localStorage.setItem(`${contentType}Timestamp`, new Date().toISOString())
  })
}

const retrievePortfolioData = ({
  contentfulDataConfig,
  fetchContentfulData,
}) => {
  contentfulDataConfig.forEach(({ contentType, setter }) => {
    const doesContentExistInLocalStorage = localStorage.getItem(contentType)
    const isContentWithinOneDayOld = !isExpired(contentType)
    const shouldUseLocalStorageContent =
      doesContentExistInLocalStorage && isContentWithinOneDayOld

    if (shouldUseLocalStorageContent) {
      useLocalStorageContent({ contentType, setter })
    } else {
      useFetchedContent({ contentType, setter, fetchContentfulData })
    }
  })
}

function App() {
  const [projects, setProjects] = useState(DEFAULT_PROJECTS)
  const [info, setInfo] = useState(DEFAULT_INFO)
  const [now, setNow] = useState(DEFAULT_NOW)
  const [timeline, setTimeline] = useState(DEFAULT_TIMELINE)

  const fetchContentfulData = useContentful()
  const contentfulDataConfig = [
    {
      contentType: PROJECTS,
      setter: setProjects,
    },
    {
      contentType: INFO,
      setter: setInfo,
    },
    {
      contentType: NOW,
      setter: setNow,
    },
    {
      contentType: TIMELINE,
      setter: setTimeline,
    },
  ]

  useEffect(() => {
    retrievePortfolioData({
      contentfulDataConfig,
      fetchContentfulData,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isAnyDataEmpty = [projects, info, now, timeline].find(isEmpty)
  if (isAnyDataEmpty) return null

  const {
    introTitle,
    introSubtitle,
    contactTitle,
    contactSubtitle,
    name,
    avatar: {
      fields: {
        file: { url: avatarUrl },
      },
    },
    portrait: {
      fields: {
        file: { url: portraitUrl },
      },
    },
    linkedin,
    github,
    instagram,
    email,
  } = info || {}

  const sortedProjects = sortBy(projects, ['order'])

  return (
    <>
      <div id="home" aria-hidden />
      <AppBar avatarUrl={`http:${avatarUrl}`} name={name} />
      <Box
        sx={{
          flexGrow: 1,
          margin: {
            xs: '0px 6px 48px',
            md: '0px 24px 48px',
          },
        }}
      >
        <Grid container spacing={2}>
          <IntroCard
            title={introTitle}
            subtitle={introSubtitle}
            width={7}
            linkedin={linkedin}
            github={github}
            instagram={instagram}
            email={email}
          />
          <SelfPortraitCard portraitUrl={`http:${portraitUrl}`} />
          <SectionTitle id="timeline">📍 The places I've been...</SectionTitle>
          <TimelineCard data={timeline} />

          <SectionTitle id="projects">🛠️ The stuff I've built...</SectionTitle>
          <ProjectCards data={sortedProjects} />

          <SectionTitle id="blogs">📝 The things I've written...</SectionTitle>
          <Blog />

          <div id="contact" />
          <IntroCard
            title={contactTitle}
            subtitle={contactSubtitle}
            width={12}
            linkedin={linkedin}
            github={github}
            instagram={instagram}
            email={email}
            containerStyles={{ marginTop: '48px' }}
          />
        </Grid>
      </Box>
    </>
  )
}

export default App
