import PropTypes from 'prop-types'
import React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import { FONT_PRIMARY } from '../styles'

const SectionsMenu = ({
  handleOpenNavMenu,
  anchorElNav,
  handleCloseNavMenu,
  sections,
}) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: 'flex', md: 'none' },
        justifyContent: 'flex-end',
      }}
    >
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="black"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {sections.map(({ label, id }) => (
          <MenuItem key={label} onClick={handleCloseNavMenu}>
            <Typography textAlign="center" sx={{ fontFamily: FONT_PRIMARY }}>
              <Link href={`#${id}`} underline="none" sx={{ color: 'inherit' }}>
                {label}
              </Link>
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

SectionsMenu.propTypes = {
  anchorElNav: PropTypes.node,
  handleCloseNavMenu: PropTypes.func.isRequired,
  handleOpenNavMenu: PropTypes.func.isRequired,
  sections: PropTypes.array.isRequired,
}

SectionsMenu.defaultProps = {
  anchorElNav: null,
}

export default SectionsMenu
