export const RED = '#D9043D'
export const MAGENTA = '#BF05F2'
export const PURPLE = '#7C05F2'
export const NEON_GREEN = '#0BD904'
export const BLACK = '#191919'
export const WHITE = '#fff'

export const COLORS = {
  Purple: '#7C05F2',
  Black: '#191919',
  Red: '#D9043D',
  NeonGreen: '#0BD904',
  Magenta: '#BF05F2',
  White: '#fff',
}

export const FONT_PRIMARY = 'Nunito, arial'
export const FONT_SECONDARY = 'Mulish, arial'

export const DEFAULT_BOX_SHADOW =
  '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'

export const TABLET_MAX_SCREEN_WIDTH = 900
export const TABLET_MEDIA_QUERY = `@media (max-width: ${TABLET_MAX_SCREEN_WIDTH}px)`
