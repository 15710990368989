import React, { useState, useEffect, useRef } from 'react'
import keyBy from 'lodash.keyby'
import styled from 'styled-components'
import clip from 'text-clipper'
import Link from '@mui/material/Link'

import Tabs from './Tabs'
import useMediumBlogPosts from './useMediumBlogPosts'
import { Subtitle, Title } from '../styled-components'
import {
  FONT_SECONDARY,
  NEON_GREEN,
  TABLET_MAX_SCREEN_WIDTH,
  TABLET_MEDIA_QUERY,
} from '../styles'
import Dropdown from './Dropdown'
import useWindowSize from '../useWindowSize'
import { mix } from 'polished'

const Wrapper = styled.div({
  margin: '24px 12px 0px 0px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  overflow: 'hidden',
})

const PostContent = styled.div({
  fontFamily: FONT_SECONDARY,
  '& figure': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 6,
  },
  '& img': {
    minWidth: 200,
    maxWidth: '50%',
    textAlign: 'center',
  },
})

const PostContentWrapper = styled.div({
  width: '70%',
  maxHeight: 500,
  overflow: 'auto',
  padding: '12px 36px',
  marginLeft: 24,
  backgroundColor: mix(0.02, NEON_GREEN, 'white'),
  borderRadius: 18,
  [TABLET_MEDIA_QUERY]: {
    width: '100%',
  },
})

const Blog = () => {
  const posts = useMediumBlogPosts()
  const postsByTitle = keyBy(posts, 'title')
  const tabs = Object.keys(postsByTitle)
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const { width: screenWidth } = useWindowSize()
  const contentRef = useRef(null)

  useEffect(() => {
    setSelectedTab(tabs[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts])

  const selectedPost = postsByTitle[selectedTab]
  if (!selectedPost) return null
  const { title, pubDate, content, link } = selectedPost
  const formattedPubDate = new Date(pubDate).toLocaleDateString()

  const handleTabClick = e => {
    setSelectedTab(e.target.textContent)
    contentRef.current.scrollTop = 0
  }

  return (
    <>
      {screenWidth <= TABLET_MAX_SCREEN_WIDTH ? (
        <Dropdown
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          tabs={tabs}
          contentRef={contentRef}
        />
      ) : null}

      <Wrapper>
        {screenWidth > TABLET_MAX_SCREEN_WIDTH ? (
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            postsByTitle={postsByTitle}
            handleTabClick={handleTabClick}
          />
        ) : null}

        <PostContentWrapper ref={contentRef}>
          <Title>{title}</Title>
          <Subtitle>{formattedPubDate}</Subtitle>
          <PostContent
            dangerouslySetInnerHTML={{
              __html: clip(content, 2000, { html: true, maxLines: 8 }),
            }}
          />
          <Link
            href={link}
            underline="none"
            target="_blank"
            noreferrer="true"
            noopener="true"
            sx={{
              textDecoration: 'underline',
              fontSize: '1rem',
              fontFamily: FONT_SECONDARY,
            }}
          >
            Read more on Medium »
          </Link>
        </PostContentWrapper>
      </Wrapper>
    </>
  )
}

export default Blog
