import PropTypes from 'prop-types'
import React from 'react'
import MuiTooltip from '@mui/material/Tooltip'

import { FONT_SECONDARY } from './styles'

const Tooltip = ({ tooltipText, children, placement }) => {
  return (
    <MuiTooltip
      title={tooltipText}
      placement={placement}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            fontFamily: FONT_SECONDARY,
            fontSize: { xs: '0.8rem', md: '0.9rem' },
            padding: '8px 12px',
          },
        },
      }}
    >
      {children}
    </MuiTooltip>
  )
}

Tooltip.propTypes = {
  children: PropTypes.any,
  tooltipText: PropTypes.string.isRequired,
}

Tooltip.defaultProps = {
  children: null,
  placement: 'bottom',
}

export default Tooltip
