import PropTypes from 'prop-types'
import React, { useState } from 'react'
import OutboundIcon from '@mui/icons-material/Outbound'
import styled from 'styled-components'
import { mix } from 'polished'

import Tooltip from './Tooltip'

import { TABLET_MEDIA_QUERY, WHITE } from './styles'
import { CenteredPhoto, Title } from './styled-components'

import ProjectModal from './ProjectModal'

const ProjectPhotoContainer = styled.div({
  flex: 1,
  margin: '12px -6px -6px',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: 18,
  minHeight: 350,
  maxHeight: 500,
  '@media (max-width: 600px)': { minHeight: 200 },
})

const Wrapper = styled.div({
  color: WHITE,
  position: 'relative',
  overflow: 'hidden',
  minWidth: 500,
  borderRadius: 18,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  cursor: 'pointer',
  [TABLET_MEDIA_QUERY]: {
    minWidth: 250,
  },
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '12px 12px 0px 24px',
})

const ProjectTitle = styled(Title)({
  fontWeight: 200,
  color: WHITE,
  fontSize: 20,
  textDecoration: 'underline',
  textDecorationStyle: 'dotted',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const ProjectCard = ({
  color,
  title,
  subtitle,
  link,
  imgSrc,
  youtubeUrl,
  tools,
}) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  const backgroundColor = mix(0.5, WHITE, color)
  return (
    <>
      <Wrapper style={{ backgroundColor }} onClick={handleModalOpen}>
        <Header>
          <Tooltip tooltipText={`${title} - ${subtitle}`}>
            <ProjectTitle>{title}</ProjectTitle>
          </Tooltip>

          <OutboundIcon
            sx={{
              color: WHITE,
              '&:hover': { color },
            }}
            fontSize="large"
          />
        </Header>

        <ProjectPhotoContainer>
          <CenteredPhoto
            src={imgSrc}
            alt={title}
            style={{ height: '100%', borderRadius: 16 }}
          />
        </ProjectPhotoContainer>
      </Wrapper>

      <ProjectModal
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        title={title}
        subtitle={subtitle}
        youtubeUrl={youtubeUrl}
        link={link}
        tools={tools}
      />
    </>
  )
}

ProjectCard.propTypes = {
  color: PropTypes.string.isRequired,
  link: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  youtubeUrl: PropTypes.string,
  tools: PropTypes.arrayOf(PropTypes.string).isRequired,
}

ProjectCard.defaultProps = {
  link: '',
  youtubeUrl: '',
}

export default ProjectCard
