import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Tab from './Tab'

const Wrapper = styled.div({
  padding: '0 24px 24px 48px',
  display: 'flex',
  flexDirection: 'column',
  width: '30%',
  maxHeight: 500,
  overflow: 'auto',
})

const Tabs = ({ tabs, selectedTab, postsByTitle, handleTabClick }) => {
  return (
    <Wrapper>
      {tabs.map(tab => (
        <Tab
          key={tab}
          text={tab}
          onClick={handleTabClick}
          imageUrl={postsByTitle[tab].thumbnail}
          isSelected={selectedTab === tab}
        />
      ))}
    </Wrapper>
  )
}

Tabs.propTypes = {
  selectedTab: PropTypes.string,
  setSelectedTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  postsByTitle: PropTypes.object.isRequired,
}

Tabs.defaultProps = {
  selectedTab: null,
}

export default Tabs
