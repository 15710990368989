import React, { useRef, useEffect } from 'react'
import Timeline from '@mui/lab/Timeline'
import orderBy from 'lodash.orderby'

import TimelineItem from './TimelineItem'

const TimelineCard = ({ data }) => {
  const sortedData = orderBy(data, ['timespan'], ['desc'])
  const containerRef = useRef(null)
  useEffect(() => {
    const container = containerRef.current
    container.scrollLeft = (container.scrollWidth - container.offsetWidth) / 2
  }, [])

  return (
    <div
      ref={containerRef}
      style={{
        overflow: 'auto',
        width: '100%',
      }}
    >
      <Timeline position="alternate" sx={{ minWidth: 400 }}>
        {sortedData.map(timelineItem => {
          const {
            event,
            description,
            timespan,
            tooltipText,
            icon: {
              fields: {
                file: { url },
              },
            },
          } = timelineItem

          return (
            <TimelineItem
              key={event}
              event={event}
              description={description}
              timespan={timespan}
              tooltipText={tooltipText}
              iconUrl={`http://${url}`}
            />
          )
        })}
      </Timeline>
    </div>
  )
}

export default TimelineCard
