import PropTypes from 'prop-types'
import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'

import { FONT_PRIMARY, WHITE } from '../styles'
import Name from './Name'
import SectionBtns from './SectionBtns'
import SectionsMenu from './SectionsMenu'

const sections = [
  { label: '🏠 Home', id: 'home' },
  { label: '📍 Timeline', id: 'timeline' },
  { label: '🛠️ Projects', id: 'projects' },
  { label: '📝 Writings', id: 'blogs' },
  { label: '📫 Contact', id: 'contact' },
]

function ResponsiveAppBar({ avatarUrl, name }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <AppBar
      position="sticky"
      style={{
        boxShadow: 'none',
        fontFamily: FONT_PRIMARY,
        backgroundColor: WHITE,
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: WHITE,
          padding: {
            xs: '0px 16px',
            md: '0px 32px',
          },
        }}
      >
        <Toolbar disableGutters sx={{ backgroundColor: 'inherit' }}>
          <Avatar alt={name} src={avatarUrl} sx={{ mr: 1.5 }} />
          <Name variant="h5">{name}</Name>

          <SectionBtns
            sections={sections}
            handleCloseNavMenu={handleCloseNavMenu}
          />
          <SectionsMenu
            handleOpenNavMenu={handleOpenNavMenu}
            anchorElNav={anchorElNav}
            handleCloseNavMenu={handleCloseNavMenu}
            sections={sections}
          />
        </Toolbar>
      </Container>
    </AppBar>
  )
}

ResponsiveAppBar.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
export default ResponsiveAppBar
