import PropTypes from 'prop-types'
import React from 'react'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'

import { FONT_SECONDARY } from './styles'

const Tools = ({ data }) => {
  return (
    <Stack
      direction="row"
      sx={{
        flexWrap: 'wrap',
        gap: '6px',
        marginBottom: '24px',
      }}
    >
      {data.map(tool => (
        <Chip
          label={tool}
          key={tool}
          size="small"
          sx={{ fontFamily: FONT_SECONDARY }}
        />
      ))}
    </Stack>
  )
}

Tools.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Tools
