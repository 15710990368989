import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@mui/material/Grid'
import styled from 'styled-components'

import { DEFAULT_BOX_SHADOW, FONT_PRIMARY } from './styles'

const InnerWrapper = styled.div({
  margin: 8,
  borderRadius: 18,
  fontFamily: FONT_PRIMARY,
  padding: 6,
  height: '100%',
  boxShadow: DEFAULT_BOX_SHADOW,
})

const Card = ({ children, width, innerStyles, containerStyles }) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={width}
      sx={{
        alignItems: 'stretch',
        margin: '12px 0px',
        ...containerStyles,
      }}
    >
      <InnerWrapper style={innerStyles}>{children}</InnerWrapper>
    </Grid>
  )
}

Card.propTypes = {
  children: PropTypes.any,
  width: PropTypes.number,
  innerStyles: PropTypes.object,
  containerStyles: PropTypes.object,
}

Card.defaultProps = {
  width: 12,
  innerStyles: {},
  containerStyles: {},
}

export default Card
