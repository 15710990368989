import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@mui/material/Typography'

import { FONT_PRIMARY, PURPLE } from '../styles'

const Name = ({ variant, sx, children }) => {
  return (
    <Typography
      variant={variant}
      noWrap
      component="a"
      href="/"
      sx={{
        fontFamily: FONT_PRIMARY,
        fontWeight: 700,
        color: PURPLE,
        mr: 2,
        flexGrow: 1,
        textTransform: 'lowercase',
        textDecoration: 'none',
        ...sx,
      }}
    >
      {children}
    </Typography>
  )
}

Name.propTypes = {
  sx: PropTypes.object,
  variant: PropTypes.string,
  children: PropTypes.any,
}

Name.defaultProps = {
  sx: {},
  variant: 'h6',
  children: null,
}

export default Name
