import styled from 'styled-components'

import { FONT_SECONDARY, DEFAULT_BOX_SHADOW, FONT_PRIMARY } from './styles'

export const Title = styled.h2({
  fontWeight: 600,
  fontSize: '1.4rem',
  lineHeight: '116.7%',
  letterSpacing: '-0.03em',
  fontFamily: FONT_PRIMARY,
})

export const Subtitle = styled.h3({
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '151.34%',
  fontFamily: FONT_SECONDARY,
})

export const SectionTitle = styled(Title)({
  width: '100%',
  margin: '64px 12px -12px',
  padding: '12px 12px 12px 24px',
})

export const CenteredPhotoContainer = styled.div({
  position: 'relative',
  maxHeight: 500,
  minHeight: 250,
  overflow: 'hidden',
  margin: '12px 24px',
})

export const CenteredPhoto = styled.img({
  objectFit: 'cover',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  borderRadius: 18,
  boxShadow: DEFAULT_BOX_SHADOW,
})
