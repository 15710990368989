import React from 'react'
import PropTypes from 'prop-types'
import MuiTimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineContent from '@mui/lab/TimelineContent'
import Typography from '@mui/material/Typography'

import Tooltip from '../Tooltip'

import { FONT_PRIMARY, FONT_SECONDARY } from '../styles'

const TimelineItem = ({
  timespan,
  event,
  description,
  tooltipText,
  iconUrl,
}) => {
  let eventComponent = (
    <Typography
      variant="h6"
      component="span"
      sx={{
        fontFamily: FONT_PRIMARY,
        fontSize: { xs: '1rem', md: '1.2rem' },
      }}
    >
      {event}
    </Typography>
  )

  if (tooltipText) {
    eventComponent = (
      <Tooltip tooltipText={tooltipText}>
        <span
          style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}
        >
          {eventComponent}
        </span>
      </Tooltip>
    )
  }
  return (
    <MuiTimelineItem>
      <TimelineOppositeContent
        sx={{
          m: 'auto 0',
          fontFamily: FONT_PRIMARY,
          fontSize: { xs: '0.7rem', md: '0.8rem' },
        }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        {timespan}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot
          sx={{
            padding: '0px',
            boxShadow: 'none',
            borderWidth: '1px',
          }}
        >
          <img
            src={iconUrl}
            alt={event}
            style={{
              width: 48,
              height: 48,
              borderRadius: '50%',
            }}
          />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '32px', px: 2 }}>
        {eventComponent}
        <Typography
          sx={{
            fontFamily: FONT_SECONDARY,
            fontSize: { xs: '0.8rem', md: '1rem' },
          }}
        >
          {description}
        </Typography>
      </TimelineContent>
    </MuiTimelineItem>
  )
}

TimelineItem.propTypes = {
  description: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  timespan: PropTypes.string.isRequired,
}

TimelineItem.defaultProps = {
  tooltipText: null,
}

export default TimelineItem
